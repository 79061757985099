import React from 'react';
import './styles.css';

const JournalEntries = () => {
    return (
        <div className="journal-entries">
            <h1 className="coming-soon">Coming Soon</h1>
        </div>
    );
};

export default JournalEntries;